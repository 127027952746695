/** @jsx jsx */
import { rgba, transparentize } from 'polished';
import { jsx, Container, Image } from 'theme-ui';
import Tabs, { TabPane } from 'rc-tabs';
import SectionHeading from 'components/section-heading';
import PieChart from 'components/icons/pie-chart';
import Cog from 'components/icons/cog';
import Currency from 'components/icons/currency';
import Play from 'components/icons/play';
import Briefcase from 'components/icons/briefcase';
import TabButton from 'components/tabs/tab-button';
import taskManager from 'assets/images/task-manager.png';
import screenDashboard from 'assets/images/clem-screen.png';
import screenReportRich from 'assets/images/clem-report-rich.png';
import screenBooking from 'assets/images/clem-booking.png';
import 'rc-tabs/assets/index.css';

const data = [
  {
    id: 1,
    title: 'Dashboard',
    icon: <PieChart fill="#4c3492" />,
    image: screenDashboard,
  },
  {
    id: 2,
    title: 'Rich reporting',
    icon: <Cog fill="#4c3492" />,
    image: screenReportRich,
  },
  {
    id: 3,
    title: 'Simple Booking',
    icon: <Play fill="#4c3492"/>,
    image: screenBooking,
  },
  // {
  //   id: 4,
  //   title: 'Budget tracker',
  //   icon: <Briefcase />,
  //   image: taskManager,
  // },
];

const Dashboard = () => {
  return (
    <section sx={styles.section} id="application">
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="Intuitive dashboard to see and iteract with all your viewings"
          description="Everything in a single place"
        />
        <Tabs sx={styles.dashboardTabs} animated={{ tabPane: true }}>
          {data.map((tab) => (
            <TabPane key={tab.id} tab={<TabButton tab={tab} />}>
              <Image src={tab.image} alt={tab.title} sx={styles.image} />
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </section>
  );
};

export default Dashboard;

const styles = {
  section: {
    backgroundColor: rgba('#7141F8', 0.03),
    pt: [8, null, null, 12],
    pb: [6, null, null, null, 12],
  },
  heading: {
    marginBottom: [6, null, null, 12],
    maxWidth: ['none', null, null, 565, null, 'none'],
    p: {
      color: rgba('#02073E', 0.7),
      maxWidth: 445,
    },
  },
  dashboardTabs: {
    borderColor: 'transparent !important',
    overflow: 'visible !important',
    border: 0,
    '.rc-tabs-nav-wrap': {
      justifyContent: 'center',
      marginBottom: 8,
      overflow: 'unset',
    },
    '.rc-tabs-ink-bar': {
      display: 'none',
    },
    '.rc-tabs-tabpane, .rc-tabs-tab-btn': {
      outline: 0,
    },
    '.rc-tabs-nav-list': {
      flexWrap: ['wrap', null, null, 'unset'],
    },
    '.rc-tabs-tab': {
      backgroundColor: 'transparent',
      ':nth-of-type(1),:nth-of-type(2)': {
        mb: [4, null, null, 0],
      },
      ':nth-of-type(2)': {
        ml: [4, null, null, 0],
      },
      ':nth-of-type(4)': {
        ml: [2, null, null, 0],
      },
      '+ .rc-tabs-tab': {
        ml: [null, null, null, 4, 8],
      },
    },
    '.rc-tabs-tab-active': {
      backgroundColor: 'white',
      fontWeight: [400, null, null, 500],
      boxShadow: '0px 4px 6px rgba(125, 128, 170, 0.08)',
      borderRadius: 5,
      padding: ['10px 10px', null, null, '10px 18px'],
    },
  },
  image: {
    borderRadius: '12px',
    boxShadow: 'rgb(76 52 146 / 20%) 0px 11px 15px -7px, rgb(76 52 146 / 14%) 0px 24px 38px 3px, rgb(76 52 146 / 12%) 0px 9px 46px 8px;',
  },
};

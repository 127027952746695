/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import Image from 'components/image';

const Feature = ({ title, description, image, reverse }) => {
  return (
    <section sx={styles.section}>
      <Container>
        {/* {
          reverse ? ( */}
            <Box sx={{ ...styles.grid, ...(reverse ? styles.gridReverse : {})}}>
              <SectionHeading
                sx={styles.heading}
                title={title}
                description={description}
              />
              <Box sx={styles.illustration}>
                <Image
                  src={
                    !!image
                      ? image.childImageSharp.gatsbyImageData
                      : {}
                  }
                  alt="Feature Image 1"
                />
              </Box>
            </Box>
          {/* ) : (
            <Box sx={styles.grid}>
              <SectionHeading
                sx={styles.heading}
                title={title}
                description={description}
              />
              <Box sx={styles.illustration}>
                <Image
                  src={
                    !!image
                      ? image.childImageSharp.gatsbyImageData
                      : {}
                  }
                  alt="Feature Image 1"
                />
              </Box>
            </Box>
          )
        } */}
      </Container>
    </section>
  );
};

export default Feature;

const styles = {
  section: {
    pt: [6, null, null, 8, 10, 11],
    pb: [8, null, null, 12, null, 14],
  },
  // grid: {
  //   display: 'grid',
  //   alignItems: 'center',
  //   gap: 10,
  //   gridTemplateColumns: ['1fr', null, null, 'repeat(2, 1fr)', '1fr 1fr'],
  // },
  grid: {
    flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap'],
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    flexDirection: 'row',
    // gridTemplateColumns: ['1fr', null, null, 'repeat(2, 1fr)', '1fr 1fr'],
  },
  gridReverse: {
    flexDirection: 'row-reverse',
  },
  heading: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    textAlign: ['center', null, null, 'left'],
    h3: {
      fontSize: [3, null, null, 6, 11],
      lineHeight: 1.53,
    },
  },
  illustration: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    textAlign: 'center',
    
    div: {
      overflow: 'visible',
    },
    img: {
      borderRadius: '12px',
      boxShadow: 'rgb(76 52 146 / 20%) 0px 11px 15px -7px, rgb(76 52 146 / 14%) 0px 24px 38px 3px, rgb(76 52 146 / 12%) 0px 9px 46px 8px;',
    },
  },
};

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Seo from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import Feature from 'sections/feature';
import Dashboard from 'sections/dashboard';
import Benefits from 'sections/benefits';
// import { Helmet } from 'react-helmet';
import { Widget } from '@typeform/embed-react'

export default function IndexPage() {
  const data = useStaticQuery(graphql`
  query {
    addons: file(relativePath: { eq: "clem-booking-zoom.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, JPG]
        )
      }
    }

    report: file(relativePath: { eq: "clem-report.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, JPG]
        )
      }
    }

    trainee: file(relativePath: { eq: "clem-trainee.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, JPG]
        )
      }
    }

    script: file(relativePath: { eq: "clem-script.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, JPG]
        )
      }
    }

    whatsapp: file(relativePath: { eq: "clem-whatsapp.png" }) {
      childImageSharp {
        gatsbyImageData(
          height: 400
          placeholder: BLURRED
          formats: [AUTO, JPG]
        )
      }
    }

    outofhour: file(relativePath: { eq: "clem-availability.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, JPG]
        )
      }
    }
  }
`);
  return (
    <Layout>
      <Seo
        title="Clem"
        description="Clem - On demand physical viewings for property managers"
      />
      <Banner />
      <div id="register-interest"></div>
      <Widget id="rvAMKYCQ" style={{ width: '100%', height: '600px' }} className="tf-form" />
      {/* <div id="register-interest" data-tf-widget="rvAMKYCQ" data-tf-hide-headers data-tf-iframe-props="title=Register" data-tf-medium="snippet" style={{ width: '100%', height: '600px' }}></div> */}
      <div id="features">
        <Feature
          title="Out of hours viewings"
          description={`Don’t delay an evening viewing request to a block viewing on Saturday. Instead, view with Clem immediately and close the customer ASAP!`}
          image={data.outofhour}
        />
        <Feature
          title="Viewers you can Trust"
          description={`Clems are trained to ensure viewings are to the highest professional standard.`}
          reverse
          image={data.trainee}
        />
        <Feature
          title="Next generation Viewings"
          description={
            <>
              <p>Clem tech & processes ensure consistent and reliable viewings.</p>
              <p>We are never late and always arrive at least 15 mins before.</p>
              <p>We always ensure the property is ready for viewing before customer arrives.</p>
              <p>We scrape the internet to collect data on the property and local services.</p>
              <p>We auto generate a script unique to each property based on local intelligence, that will be delivered to the customer by Clems.</p>
              <p>By gathering data from the moment you book to when you receive the keys back we generate a detailed report on each viewing.</p>
            </>
          }
          image={data.script}
        />
        <Feature
          title="Real time reminders"
          description={`Stay constantly informed and minimise no shows with real time updates.`}
          image={data.whatsapp}
          reverse
        />
        <Feature
          title="Rich reporting"
          description={`Instantly receive a report with a visual timeline of events giving you complete trust in the viewing process`}
          image={data.report}
        />
        <Dashboard />
      </div>
      <Benefits />
      {/* <Testimonials /> */}
      {/* <Security /> */}
      {/* <MobileApp /> */}
      {/* <Faq /> */}
      {/* <Helmet>
        <script src="https://embed.typeform.com/next/embed.js" />
      </Helmet> */}
      {/* <div dangerouslySetInnerHTML={{ __html: `<script src="https://embed.typeform.com/next/embed.js" defer />` }} /> */}
    </Layout>
  );
}

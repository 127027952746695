/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import Feature from 'components/cards/feature';

import iconAccount from 'assets/images/icons/account.png';
import iconDocument from 'assets/images/icons/document.png';
import iconLightningBolt from 'assets/images/icons/lightning-bolt.png';
import iconMapMarker from 'assets/images/icons/map-marker.png';
import iconMoneyBag from 'assets/images/icons/money-bag.png';
import iconTime from 'assets/images/icons/time.png';

const data = [
  {
    id: 1,
    icon: iconMoneyBag,
    title: 'Save Money',
    description: `Cut from 30% to 50% off your viewing expenses and transform fixed cost into variable cost.`,
    moreLink: '#',
  },
  {
    id: 2,
    icon: iconTime,
    title: 'Save Time',
    description: `On average we free up 40% of your time allowing you to concentrate on higher value tasks such as gaining more instructions.`,
    moreLink: '#',
  },
  {
    id: 3,
    icon: iconLightningBolt,
    title: 'Close tenants faster',
    description: `Close 5X faster by showing properties immediately according to customers' availability instead of yours. Never risk losing a customer because of availability again.`,
    moreLink: '#',
  },
  {
    id: 4,
    icon: iconMapMarker,
    title: 'Expand your territory',
    description: `Expand your business to out of reach areas by removing physical barriers. Distance is no longer a limit.`,
    moreLink: '#',
  },
  {
    id: 5,
    icon: iconAccount,
    title: 'Set your business up for scale',
    description: `On demand and decentralised viewings allow your business to scale up and down.`,
    moreLink: '#',
  },
  // {
  //   id: 6,
  //   icon: icon3,
  //   title: 'Ultimate Org Support',
  //   description: `Get your info tests delivered at home collect a sample from the your progress tests.`,
  //   moreLink: '#',
  // },
];

const Benefits = () => {
  return (
    <section sx={styles.section} id="benefits">
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="Powerful yet simple"
          description="A complete solution for physical viewings"
        />
        <Box sx={styles.features}>
          {data?.map((item) => (
            <Feature key={item.id} className="feature-item" data={item} />
          ))}
        </Box>
      </Container>
    </section>
  );
};

export default Benefits;

const styles = {
  section: {
    pt: [12],
    pb: [6, null, null, 8, 15],
  },
  heading: {
    marginBottom: [40, 50, 60, 80],
    maxWidth: ['none', null, null, 565, null, 'none'],
  },
  features: {
    gap: [6, null, null, '90px 40px'],
    display: ['grid'],
    maxWidth: 1175,
    mx: 'auto',
    justifyContent: ['center', null, null, 'unset'],
    gridTemplateColumns: [
      'repeat(1, 300px)',
      null,
      null,
      'repeat(2, 1fr)',
      'repeat(3, 1fr)',
    ],
  },
};
